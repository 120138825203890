<template>
  <div >
    <div class="home-banner-area mb-4 pt-3">
      <div class="container">
        <div class="row gutters-10 position-relative">
          <div class="col-lg-3 position-static d-none d-lg-block">
            <div class="aiz-category-menu bg-white rounded shadow-sm" id="category-sidebar">
              <div class="p-3 d-none d-lg-block rounded-top all-category position-relative text-left">
                <span class="fw-600 fs-16 mr-3">{{ $t('lei-bie') }}</span>
                <a  class="text-reset" @click="toPath('category')">
                  <span class="d-none d-lg-inline-block">{{ $t('kan-dao-suo-you') }} ></span>
                </a>
              </div>
              <ul class="list-unstyled categories no-scrollbar py-2 mb-0 text-left">
                <li class="category-nav-element" v-for="(item, i) in categoryLis2" :key="i" @click="toCategoryDetail(item)">
                  <a class="text-truncate text-reset py-2 px-3 d-block">
                    <img  class="cat-image lazyload mr-2 opacity-60" :src="item.Img ? `${item.Img}` : categoryUrl" width="16">
                    <span class="cat-name">{{item.Name}}</span>
                  </a>
                  <!-- <div class="sub-cat-menu c-scrollbar-light rounded shadow-lg p-4">
                    <div class="c-preloader text-center absolute-center">
                      <i class="las la-spinner la-spin la-3x opacity-70"></i>
                    </div>
                  </div> -->
                </li>
              </ul>
            </div>
          </div>

          <div :class="showOrder ? 'col-lg-7' : 'col-lg-9'">
            <div class="aiz-carousel dots-inside-bottom mobile-img-auto-height slick-initialized slick-slider slick-dotted">
              <button type="button" class="slick-prev slick-arrow" aria-disabled="false" style=""  @click="bannerPrev('banner')" v-if="bannerlist.length > 1">
                <i class="las la-angle-left"></i> 
              </button>
              <Swipe ref="banner" :autoplay="3000" :touchable="touchable" indicator-color="#e62e04" class="home-banner">
                <SwipeItem  v-for="(item, i) in bannerlist" :key="i">
                  <div class="aiz-carousel dots-inside-bottom mobile-img-auto-height" data-arrows="true" data-dots="true" data-autoplay="true">
                    <div class="carousel-box">
                      <a >
                        <img class="d-block mw-100 img-fit rounded shadow-sm overflow-hidden"  :src="`${item.Img}`" :height="showBottom ? 315 : 457">
                      </a>
                    </div>
                  </div>
                </SwipeItem>
              </Swipe>
              <button type="button" class="slick-next slick-arrow slick-disabled" style="" aria-disabled="true"  @click="bannerNext('banner')" v-if="bannerlist.length > 1">
                <i class="las la-angle-right"></i>
              </button>
            </div>

            <ul class="list-unstyled mb-0 row gutters-5" v-show="showBottom">
              <li class="minw-0 col-4 col-md mt-3" v-for="(item, i) in categoryLis2" :key="i"  @click="toCategoryDetail(item)">
                <a class="d-block rounded bg-white p-2 text-reset shadow-sm">
                  <img  :src="item.Img ? `${item.Img}` : categoryUrl"  class="lazyload img-fit"  height="78" >
                  <div class="text-truncate fs-12 fw-600 mt-2 opacity-70">{{ item.Name }}</div>
                </a>
              </li>
            </ul>
          </div>

          <div v-if="showOrder" class="col-lg-2 order-3 mt-3 mt-lg-0">
            <div class="bg-white rounded shadow-sm">
              <div class=" rounded-top p-3 d-flex align-items-center justify-content-center">
                <span class="fw-600 fs-16 mr-2 text-truncate">
                  {{ $t('jin-ri-jiao-yi') }} </span>
                <span class="badge badge-primary badge-inline">{{ $t('re-men') }}</span>
              </div>
              <div class="c-scrollbar-light overflow-auto h-lg-400px p-2 bg-primary rounded-bottom">
                <div class="gutters-5 lg-no-gutters row row-cols-2 row-cols-lg-1">
                  <div class="col mb-2" v-for="(item,i) in dealGoodsList" :key="i">
                    <a class="d-block p-2 text-reset bg-white h-100 rounded">
                      <div class="row gutters-5 align-items-center">
                        <div class="col-xxl">
                          <div class="img">
                            <img class="lazyload img-fit h-140px h-lg-80px" :src="item.url">
                          </div>
                        </div>
                        <div class="col-xxl">
                          <div class="fs-16">
                            <span class="d-block text-primary fw-600">500</span>
                            <del class="d-block opacity-70">520</del>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--二级轮播图-->
    <!-- <div class="mb-4">
      <div class="container">
        <div class="row gutters-10">
          <div class="col-xl col-md-6" v-for="(item, i) in bannerList2" :key="i">
            <div class="mb-3 mb-lg-0">
              <a class="d-block text-reset">
                <img :src="item.url" class="img-fluid lazyload w-100">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--限时秒杀-->
    <!-- <section class="mb-4">
      <div class="container">
        <div class="px-2 py-4 px-md-4 py-md-3 bg-white shadow-sm rounded">
          <div class="d-flex flex-wrap mb-3 align-items-baseline border-bottom">
            <h3 class="h5 fw-700 mb-0">
              <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">限时秒杀</span>
            </h3>
            <div class="aiz-count-down ml-auto ml-lg-3 align-items-center" data-date="50:08:23:44">
              <div class="countdown-item">
                <span class="countdown-digit">50</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">08</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">09</span>
              </div>
              <span class="countdown-separator">:</span>
              <div class="countdown-item">
                <span class="countdown-digit">54</span>
              </div>
            </div>
            <a href="javascript:void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md w-100 w-md-auto">查看更多</a>
          </div>

          <div class="aiz-carousel gutters-10 half-outside-arrow slick-initialized slick-slider" >
            <button type="button" class="slick-prev slick-arrow" style="" @click="bannerPrev('fastGoods')" v-if="fastGoodsList.length > 1">
              <i class="las la-angle-left"></i>
            </button>
            <Swipe ref="fastGoods"  :touchable="false" :width="224">
              <SwipeItem  v-for="(item, i) in fastGoodsList" :key="i">
                <goodsListBar :goodsInfo="item"></goodsListBar>
              </SwipeItem>
            </Swipe>
            <button type="button" class="slick-next slick-arrow" style=""   @click="bannerNext('fastGoods')" v-if="fastGoodsList.length > 1">
              <i class="las la-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section> -->

    <!--商品列表-->
    <section class="mb-4" v-for="(classify, index) in classifyList" :key="index">
      <div class="container">
        <div class="px-2 py-4 px-md-4 py-md-3 bg-white shadow-sm rounded">
          <div class="d-flex flex-wrap mb-3 align-items-baseline border-bottom">
            <h3 class="h5 fw-700 mb-0">
              <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">{{classify.name}}</span>
            </h3>
            <a v-if="classify.btn" href="javascript:void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md w-100 w-md-auto">{{classify.btn}}</a>
          </div>

          <div class="aiz-carousel gutters-10 half-outside-arrow slick-initialized slick-slider" >
            <button type="button" class="slick-prev slick-arrow" style="" @click="bannerPrev(classify.key, true)" v-if="classify.goodsList.length > 1">
              <i class="las la-angle-left"></i>
            </button>
            <Swipe :ref="classify.key"  :touchable="touchable" :width="224" :show-indicators="false" :loop="false"> 
              <SwipeItem  v-for="(item, i) in classify.goodsList" :key="i">
                <goodsListBar :goodsInfo="item"></goodsListBar>
              </SwipeItem>
            </Swipe>
            <button type="button" class="slick-next slick-arrow" style=""   @click="bannerNext(classify.key, true)" v-if="classify.goodsList.length > 1">
              <i class="las la-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!--二级轮播图-->
    <!-- <div class="mb-4">
      <div class="container">
        <div class="row gutters-10">
          <div class="col-xl col-md-6" v-for="(item, i) in bannerList2" :key="i">
            <div class="mb-3 mb-lg-0">
              <a class="d-block text-reset">
                <img :src="item.url" class="img-fluid lazyload w-100">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

     <!--商品列表-->
    <section class="mb-4" v-for="(classify, index) in classifyList2" :key="`list2-${index}`">
      <div class="container">
        <div class="px-2 py-4 px-md-4 py-md-3 bg-white shadow-sm rounded">
          <div class="d-flex flex-wrap mb-3 align-items-baseline border-bottom">
            <h3 class="h5 fw-700 mb-0">
              <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">{{classify.name}}</span>
            </h3>
            <a  @click="toMore(classify)" href="javascript:void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md w-100 w-md-auto">{{ $t('cha-kan-geng-duo') }}</a>
          </div>

          <div class="aiz-carousel gutters-10 half-outside-arrow slick-initialized slick-slider" >
            <button type="button" class="slick-prev slick-arrow" style="" @click="bannerPrev(classify.key, true)" v-if="classify.goodsList.length > 1">
              <i class="las la-angle-left"></i>
            </button>
            <Swipe :ref="classify.key"  :touchable="touchable" :width="224" :show-indicators="false" :loop="false"> 
              <SwipeItem  v-for="(item, i) in classify.goodsList" :key="i">
                <goodsListBar :goodsInfo="item"></goodsListBar>
              </SwipeItem>
            </Swipe>
            <button type="button" class="slick-next slick-arrow" style=""   @click="bannerNext(classify.key, true)" v-if="classify.goodsList.length > 1">
              <i class="las la-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!--二级轮播图-->
    <!-- <div class="mb-4">
      <div class="container">
        <div class="row gutters-10">
          <div class="col-xl col-md-6" v-for="(item, i) in bannerList3" :key="i">
            <div class="mb-3 mb-lg-0">
              <a class="d-block text-reset">
                <img :src="item.url" class="img-fluid lazyload w-100">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--最畅销-->
    <!-- <section class="mb-4" >
      <div class="container">
        <div class="px-2 py-4 px-md-4 py-md-3 bg-white shadow-sm rounded">
          <div class="d-flex flex-wrap mb-3 align-items-baseline border-bottom">
            <h3 class="h5 fw-700 mb-0">
              <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">最畅销</span>
            </h3>
            <a  href="javascript:void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md w-100 w-md-auto">查看所有卖家</a>
          </div>
          

          <div class="aiz-carousel gutters-10 half-outside-arrow slick-initialized slick-slider" >
             <Swipe   :width="448" :show-indicators="false" :loop="false"> 
              <SwipeItem  v-for="(item, i) in bestGoodsList" :key="i">
                <div v-for="m in 2" :key="m">
                  <div class="carousel-box" style="width: 100%; display: inline-block;">
                    <div class="row no-gutters box-3 align-items-center border border-light rounded hov-shadow-md my-2 has-transition">
                      <div class="col-4">
                        <a href="javascript: void(0)" class="d-block p-3" tabindex="0">
                          <img src="https://shop-tk.club/public/uploads/all/oQgaV7OPdfbtMDOBzTUC5gmroL2sz2yY99f2FSur.jpg"  class="img-fluid ls-is-cached lazyloaded">
                        </a>
                      </div>
                      <div class="col-8 border-left border-light">
                        <div class="p-3 text-left">
                          <h2 class="h6 fw-600 text-truncate">
                            <a href="javascript: void(0)" class="text-reset" tabindex="0">متجر التوبة</a>
                          </h2>
                          <div class="rating rating-sm mb-2">
                            <i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i>
                          </div>
                          <a href="javascript: void(0)" class="btn btn-soft-primary btn-sm" tabindex="0">
                            访问商店 <i class="las la-angle-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeItem>
             </Swipe>
          </div>
        </div>
      </div>
    </section> -->

    <!--十大品牌-->
    <section class="mb-4">
      <div class="container">
        <div class="row gutters-10">
          <div class="col-lg-12">
            <div class="d-flex mb-3 align-items-baseline border-bottom">
              <h3 class="h5 fw-700 mb-0">
                <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">{{ $t('qian-10-ming-lei-bie') }}</span>
              </h3>
              <a href="javascript: void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md">{{ $t('cha-kan-suo-you-lei-bie') }}</a>
            </div>
            <div class="row gutters-5">
                      
              <div class="col-sm-6" v-for="(item, i) in categoryList" :key="i"  @click="toCategoryDetail(item)">
                <a  class="bg-white border d-block text-reset rounded p-2 hov-shadow-md mb-2">
                  <div class="row align-items-center no-gutters">
                    <div class="col-3 text-center">
                      <img :src="item.Img || categoryUrl"  class="img-fluid img lazyload h-60px"  >
                    </div>
                    <div class="col-7">
                      <div class="text-truncat-2 pl-3 fs-14 fw-600 text-left">{{ item.Name }}</div>
                    </div>
                    <div class="col-2 text-center">
                      <i class="la la-angle-right text-primary"></i>
                    </div>
                  </div>
                </a>
              </div>
                                
            </div>
          </div>
                
          <!-- <div class="col-lg-6">
            <div class="d-flex mb-3 align-items-baseline border-bottom">
              <h3 class="h5 fw-700 mb-0">
                <span class="border-bottom border-primary border-width-2 pb-3 d-inline-block">{{ $t('shi-da-pin-pai') }}</span>
              </h3>
              <a href="javascript: void(0)" class="ml-auto mr-0 btn btn-primary btn-sm shadow-md">{{ $t('cha-kan-suo-you-pin-pai') }}</a>
            </div>
            <div class="row gutters-5">
              
              <div class="col-sm-6" v-for="(item, i) in topCompanyList" :key="i">
                <a  class="bg-white border d-block text-reset rounded p-2 hov-shadow-md mb-2">
                  <div class="row align-items-center no-gutters">
                    <div class="col-4 text-center">
                      <img :src="item.url"  class="img-fluid img lazyload h-60px"  >
                    </div>
                    <div class="col-6">
                      <div class="text-truncate-2 pl-3 fs-14 fw-600 text-left">{{ item.name }}</div>
                    </div>
                    <div class="col-2 text-center">
                      <i class="la la-angle-right text-primary"></i>
                    </div>
                  </div>
                </a>
              </div>

            </div>
          </div> -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { Button, Popup, Picker, NoticeBar, Swipe, SwipeItem , Icon} from 'vant';
import { getBannerList  } from '@/api/user'
import { getCategoryList, getGoodsList, goodsGroup  } from '@/api/index'
import goodsListBar from '@/components/goodsList'
import moment from 'moment'
export default {
  name: 'about',
  components: {
    goodsListBar,
    Button,
    Picker,
    Popup,
    NoticeBar,
    Swipe,
    SwipeItem,
    Icon
  },
  data() {
    return {
      categoryUrl: require('../../assets/imgs/icon-category1.jpg'),
      showOrder: false,
      showBottom: true,
      bannerlist: [],
      dealGoodsList: [],
      bannerList2: [
        {
          url: require('../../assets/imgs/banner5.png'),
        },
        {
          url: require('../../assets/imgs/banner6.png'),
        },
        {
          url: require('../../assets/imgs/banner7.png'),
        },
      ],
      bannerList3: [
        {
          url: require('../../assets/imgs/banner8.png'),
        },
        {
          url: require('../../assets/imgs/banner9.png'),
        },
        {
          url: require('../../assets/imgs/banner10.png'),
        },
      ],
      fastGoodsList: [], // 限时秒杀
      goodsList: [], // 新产品
      topCompanyList: [], // 十大品牌
      bestGoodsList: [], // 最畅销
      classifyList: [
        // {
        //   name: '新产品',
        //   key: 'newProduct',
        //   goodsList: []
        // },
        // {
        //   name: '特色产品',
        //   key: 'specialProduct',
        //   goodsList: []
        // },
        // {
        //   name: '最畅销',
        //   btn: '前20名',
        //   key: 'bestProduct',
        //   goodsList: []
        // },
      ],
      classifyList2: [],
      categoryList: [],
      categoryLis2: [],
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    touchable() {
      return this.$store.state.touchable
    },
    categoryListBak() {
      return this.$store.state.categoryList
    },
  },
  watch: {
    categoryListBak(val) {
      // if (val && val.length > 0) {
      //   this.categoryList = this.categoryListBak.slice(0, 10)
      //   this.getGoods()
      // }
    }
  },
  mounted() {
    this.init()
    // if (this.categoryListBak && this.categoryListBak.length > 0) {
    //   this.categoryList = this.categoryListBak.slice(0, 10)
    //   this.getGoods()
    // }
  },
  methods: {
    init() {
      getBannerList().then(res => {
        this.bannerlist = res.data.Items
      })
      let f = new FormData()
      f.append('Pid', 0)
      f.append('pageSize', 11)
      getCategoryList(f).then(res => {
        console.log("res")
        console.log( res.data.Items)
        this.categoryLis2 = res.data.Items
      })
      goodsGroup().then(res => {
        this.categoryList = res.data.category.slice(0, 10)
        let allCategory = res.data.category
        let allGoods = res.data.goodsList
        allCategory.forEach(v => {
        let key =`categoryProduct${v.ID}`
        let param = {
          name: v.Name,
          key: key,
          id: v.ID,
          goodsList: allGoods.filter(val => {
            return val.CategoryId == v.ID
          })
        }
        this.classifyList2.push(param)
      })
      })
    },
    // getGoods() {
    //   this.categoryList.forEach(v => {
    //     let key =`categoryProduct${v.ID}`
    //     let param = {
    //       name: v.Name,
    //       key: key,
    //       goodsList: []
    //     }
    //     this.classifyList2.push(param)
    //     this.initGoods(v.ID, key)
    //   })
    // },
    initGoods(id, key) {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('categoryId', id)
      getGoodsList(form).then(res => {
        if (res.code == 0) {
          let index = this.classifyList2.findIndex(v => {
            return v.key == key
          })
          this.classifyList2[index].goodsList = res.data.Items
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    bannerPrev(ref, isList) {
      if (isList) {
        this.$refs[ref][0].prev()
      } else {
        this.$refs[ref].prev()
      }
    },
    bannerNext(ref, isList) {
      if (isList) {
        this.$refs[ref][0].next()
      } else {
        this.$refs[ref].next()
      }
    },
    toMore(data) {
      this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.name,
          id: data.id
        }
      })
    },
    toCategoryDetail(data) {
      this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
    }
  },
}
</script>
<style lang="less">

</style>
